import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../../components/global/Layout"
import { PortableText } from "@portabletext/react"
import Seo from "../../components/global/Seo"

export default function Legal({ data }) {
  const seo = data.sanityLegal.seo
  return (
    <Layout>
      <Seo
        title={seo?.title_tag}
        description={seo?.meta_description}
      />
      <div className="section">
        <div className="container md-content">
          <div>
            {data.sanityLegal.title && <h1 className="pb-10 text-center">{data.sanityLegal.title}</h1>}
            {data.sanityLegal._rawContent &&
              <PortableText
                value={data.sanityLegal._rawContent}
              />
            }
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
query ($id: String) {
  sanityLegal(id: {eq: $id}) {
    _rawContent
    title
    seo {
      meta_description
      title_tag
    }
  }
}

`